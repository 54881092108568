import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Menu from '../components/menu';
import Footer from '../components/footer';
import { AppContext, App } from '../components/app';
import useAxios from 'axios-hooks';
import axios from 'axios';
import { navigate } from 'gatsby';

const LoginPage = ({ location }) => {
    const [webmasterId, setWebmasterId] = useState('');
    const [isError, showError] = useState(false);

    const context = useContext(AppContext);
    const { state, dispatch } = context;

    const submitForm = async e => {
        e.preventDefault();
        showError(false);
        const id = webmasterId.trim();
        if (id !== '') {
            const response = await axios({
                url:
                    'https://s88v5aj97b.execute-api.eu-west-1.amazonaws.com/production/about.html',
                params: {
                    loginsent: 1,
                    username: id,
                    json: 1,
                },
            });
            if (response.data) {
                if (response.data.result === 0) {
                    showError(true);
                } else if (response.data.result === 1) {
                    dispatch({ type: 'login', affiliateId: id });
                    navigate('/tools-fhg');
                }
            }
        }
    };

    return (
        <Layout>
            <Menu location={location} />
            <h1 className="huge padding-bottom-32">Login</h1>

            <form onSubmit={submitForm}>
                <div className="forms">
                    <div className="forms-fix newsletter">
                        <div className="noborder padding-bottom-0">
                            {isError && (
                                <div class="alert">
                                    <h4>Invalid affiliate ID</h4>
                                </div>
                            )}
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="news-field"
                                    value={webmasterId}
                                    onChange={e =>
                                        setWebmasterId(e.target.value)
                                    }
                                    className={
                                        webmasterId.trim() !== ''
                                            ? 'has-value'
                                            : ''
                                    }
                                />
                                <label
                                    className="control-label"
                                    htmlFor="news-field"
                                >
                                    Enter your affiliate ID
                                </label>
                            </div>
                            <div className="button-container">
                                <button className="button full" type="submit">
                                    <span>Login</span>
                                </button>
                            </div>
                        </div>
                        <div className="spacer spacer-32">&nbsp;</div>
                    </div>
                </div>
            </form>
            <p className="small">
                Please use the affiliate ID you received for the BCASH4YOU
                affiliate program. You can find it in the CCBill admin.
            </p>
            <div className="spacer line spacer-80">&nbsp;</div>
            <p className="link link-button">
                <a
                    href="https://admin.ccbill.com/"
                    title="Forgot your affiliate ID?"
                >
                    Forgot your affiliate ID?
                </a>
            </p>
            <Footer />
        </Layout>
    );
};

export default LoginPage;
